body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.white_button{
  margin-right: 12px;
  border: 0;
  height: auto;
  min-height:24px;
  min-width:88px;
  width:auto;
  font-size:12px;
  background: #FFFFFF;
  background-image: linear-gradient(0deg, #F4F5F7 0%, #FFFFFF 100%);
  box-shadow: inset 0 -1px 1px 0 rgba(67,90,111,0.09), inset 0 0 0 1px rgba(67,90,111,0.14);
  border-radius: 3px;
  color:black !important;
}

.blue_button{
  height: auto;
  min-height:24px;
  min-width:75px;
  width:auto;
  background-image: linear-gradient(0deg, #007EF4 0%, #0788DE 100%);
  box-shadow: inset 0 -1px 1px 0 rgba(67,90,111,0.20), inset 0 0 0 1px rgba(0,34,66,0.20);
  border-radius: 3px;
  font-size:12px;
  border: 0;
  color:white;

}

.downtime{
  background-color: white;
  width:100vw;
  height: 100vh;
  z-index: 100;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
}

.downtime_child{
  width: 50%;
  /* color: red; */
  height: 200px;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 8px 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px 20px;
  border-radius: 6px;
}

.search_component_container_ideal {
  display:flex; 
  align-items:center; 
  border:1px solid #C5C7CF;
  border-radius: 2px;
  margin: 0px 16px 0px 16px;
  background-color: white;
}

.search_component_inner_container {
  width:100%; 
  margin-right:16px; 
  font-size:12px;
  padding:6px 12px 6px 12px;
  border:0px;
}

.search_component_container_ideal:focus-within {
  box-shadow: 0 0 0 0.1rem rgb(0 123 255 / 25%);
  border: 1px solid #0078FF;
}

.search_component_inner_container:focus-visible{
  outline: none;
}

.premium_tag{
	background-color: #CCF4E3;
	color: #23C16B;
	border-radius: 50px;
	font-size: 12px;
	padding: 2px 10px;
}