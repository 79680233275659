.btn-google {
    color: #fff;
    background-color: #dd4b39;
    border-color: rgba(0,0,0,0.2);
}

.btn-social {
    position: relative;
    padding-left: 44px;
    text-align: left;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.btn-block {
    display: block;
    width: 100%;
}

.btn_login{
    font-size: 14px !important;
    height: 34px;
    display: flex;
    align-items: center;
    width:100%;
}

.input_login{
    height: 42px !important;
    /* width: 350px !important; */
    font-size: 14px !important;
    border-radius: 6px;
    border: 1px solid #ced4da;
}

.input_login:focus{
    /* height: 42px !important; */
    /* width: 350px !important; */
    /* font-size: 14px !important; */
    /* border-color: #86b7fe; */
    box-shadow: 0 0 0 1px rgb(13 110 253 / 25%) !important;
}

.input_login:focus-within{
    /* height: 42px !important; */
    /* width: 350px !important; */
    /* font-size: 14px !important; */
    border-color: #86b7fe;
    box-shadow: 0 0 0 1px rgb(13 110 253 / 25%) !important;
}

#login_password{
    border: none !important;
}

#login_password:focus{
    border: none !important;
    box-shadow: none !important;
}

/* .btn_google:hover {
    color: #fff !important;
    background-color: #c23321 !important;
    border-color: rgba(0,0,0,0.2) !important;
} */
.btn_linkedin:hover {
    color: #fff !important;
    background-color: #005983 !important;
    border-color: rgba(0,0,0,0.2) !important;
}

.forgot_password_hover:hover{
    color: #1634dd !important;
}
/* , .btn-success:focus, .btn-success:active, .btn-success.active, .open .dropdown-toggle.btn-success  */
.btn_success:hover{
    background-color: #1ABB9C !important;
    border-color: #1ABB9C i !important;
    color: #FFFFFF i !important;
}
.btn_default:hover {
    color: #333 i !important;
    background-color: #e6e6e6 !important;
    border-color: #adadad i !important;
}
.login_icons{
    width: 32px;
    line-height: 34px;
    text-align: center;
    border-right: 1px solid rgba(0,0,0,0.2);
}

.sign_up_text_color{
    color:#73879C ;
    font-size:13px;
    font-weight: bold;
}

/* store options style */
/* .store_options_card{
    width: 100%;
    height: auto;
    position: absolute;
    left: calc((100% - 350px) / 2);
    top: 5%;
} */

.all_store_options_div{
    overflow: auto;
    max-height:300px;
    padding:20px;
}

.single_store{
    height:50px;
    padding:0px 20px;
    margin-bottom:5px;
    background-color:#f9f9f9;
    font-size:14px;
    display:flex;
    align-items:center;
}

.single_store:hover{
    background-color:#f2f2f2 !important;
}

.single_store_text{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width:100%;
    text-transform: capitalize;
}
.single_store_icon{
    font-size: 20px;
    margin-right: 10px;
    color:#404040;
}

.flex_display {
    display: flex;
}

.flex_center {
    display: flex;
    align-items: center;
    justify-content: center;
}

.googleLoginLogo {
    vertical-align:top;
    height: 30px;
}

.inf_text_description {
    text-transform: capitalize;
    font-size: 30px;
    font-weight: 500;
    color: rgba(33, 61, 95, 1);
}

.light_text {
    color:rgba(103, 104, 120, 1);
}

.marginBottom24 {
    margin-bottom: 24px !important;
}

#marginBottom0 {
    margin-bottom: 0px !important;
    transition: ease-in;
}

.scaleonhover:hover{
    transform: scale(1.05);
}

#image_cross_fading {
    position: relative;
}

#image_cross_fading img {
    position: absolute;
    animation-name: crossfading;
    animation-timing-function: ease-in-out;
    animation-iteration-count: infinite;
    animation-duration: 12s;
}

#image_cross_fading img:nth-of-type(1) {
    animation-delay: 8s;
}

#image_cross_fading img:nth-of-type(2) {
    animation-delay: 4s;
}

#image_cross_fading img:nth-of-type(3) {
    animation-delay: 0s;
}

@keyframes crossfading {
    0% {
        opacity:1;
    }
    17% {
        opacity:1;
    }
    33% {
        opacity:0;
    }
    83% {
        opacity:0;
    }
    100% {
        opacity:1;
    }
}